import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <p>{`Mondaic AG (“us”, “we”, or “our”) develops and distributes software (including
but not limited to the Salvus Software Suite), operates the website
`}<a parentName="p" {...{
        "href": "https://www.mondaic.com"
      }}>{`https://www.mondaic.com`}</a>{` that also hosts the Mondaic license server and download
tool, and operates the user forum `}<a parentName="p" {...{
        "href": "https://forum.mondaic.com."
      }}>{`https://forum.mondaic.com.`}</a>{` Hereinafter, these
are collectively referred to as “Services”.`}</p>
    <p>{`This page informs you of our policies regarding the collection, use and
disclosure of personal data when you use our Services and the choices you have
associated with that data.  We use your data to provide and improve the Service.
By using the Service, you agree to the collection and use of information in
accordance with this Privacy Policy.`}</p>
    <h2>{`Who is responsible for data collected about me?`}</h2>
    <p>{`Mondaic AG is the responsible party, or controller, for the data we collect and
process for our own purposes. For instance, Mondaic AG is responsible for data
about our customers, employees, and visitors to our own websites and forums.`}</p>
    <h2>{`Data collection`}</h2>
    <h3>{`What data do we collect?`}</h3>
    <p>{`Mondaic AG does not intentionally collect or process sensitive personal
information, such as government identification numbers, information on racial or
ethnic origin, political opinions, genetic data, biometric data, health data, or
any of the special categories of personal data specified by the GDPR.`}</p>
    <h4>{`Personal data`}</h4>
    <p>{`While using our Services, we may ask you to provide us with certain
personally identifiable information that can be used to contact or identify
you (“Personal Data”). Personally identifiable information may include, but
is not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Email address`}</li>
      <li parentName="ul">{`First name and last name`}</li>
      <li parentName="ul">{`Phone number`}</li>
      <li parentName="ul">{`Address, State, Province, ZIP/Postal code, City`}</li>
      <li parentName="ul">{`Information required to process payments, such as credit card details and
other banking information.`}</li>
    </ul>
    <h4>{`Contact-necessary data`}</h4>
    <p>{`While using our website to contact us via our "Contact Us" form, we gather your
personal information as filled out by you, which is subsequently transferred to
`}<a parentName="p" {...{
        "href": "https://monday.com/l/privacy/monday-com-is-gdpr-ready/"
      }}>{`Monday.com`}</a>{`.
Additionally, when interacting with said form, Monday.com will create cookies
relevant to the operation of this form.`}</p>
    <h4>{`License server usage data`}</h4>
    <p>{`To use the Services, communication with Mondaic's license server is required
to validate the license. In this process, we may collect information on how our
Services are accessed and used. Collected data includes:`}</p>
    <ul>
      <li parentName="ul">{`User name and password which is linked to your personal account information.`}</li>
      <li parentName="ul">{`The time and date of your request.`}</li>
      <li parentName="ul">{`IP addresses and operating system from computers you are using to connect to
the license server.`}</li>
      <li parentName="ul">{`Which software product from Mondaic AG you are using, on how many processes
you are using it, and for how long you are using it.`}</li>
      <li parentName="ul">{`Only when using a license token: A non-reversible hash/fingerprint of input
files used to control software packages of Mondaic AG.`}</li>
      <li parentName="ul">{`Only when using a license token: A non-reversible hash/fingerprint of the
machine used to run software from Mondaic AG.`}</li>
    </ul>
    <h4>{`Forum usage data`}</h4>
    <p>{`When using the Mondaic forum, Mondaic AG collects data necessary to provide the
functionality of the forum. Collected data may include, but is not limited to:`}</p>
    <ul>
      <li parentName="ul">{`When you are active on, participating in, or browsing the forum.`}</li>
      <li parentName="ul">{`The contents of your posts.`}</li>
      <li parentName="ul">{`Data about bookmarks, likes, and links you follow in order to share that data
with others through the forum.`}</li>
      <li parentName="ul">{`Data about private messages you send through the forum. Mondaic AG makes
private messages available to senders, their recipients, and also to forum
administrators.`}</li>
      <li parentName="ul">{`Reading time per post.`}</li>
      <li parentName="ul">{`User profiles viewed.`}</li>
      <li parentName="ul">{`IP addresses.`}</li>
    </ul>
    <h3>{`How do we collect your data?`}</h3>
    <p>{`You directly provide data to us when you`}</p>
    <ul>
      <li parentName="ul">{`Register a user account to obtain a license file,`}</li>
      <li parentName="ul">{`Get in touch with us by email, use our support channels and message boards, or
voluntarily complete a customer survey`}</li>
      <li parentName="ul">{`Use or view our website via your browser’s cookies.`}</li>
      <li parentName="ul">{`When you browse or otherwise interact with the forum in any way.`}</li>
      <li parentName="ul">{`When you send requests to the Mondaic license server. This happens when using
one of Mondaic's software products.`}</li>
    </ul>
    <h2>{`How will we use your data?`}</h2>
    <p>{`Mondaic AG collects your data so that we can`}</p>
    <h3>{`General Data Usage`}</h3>
    <ul>
      <li parentName="ul">{`Manage your account`}</li>
      <li parentName="ul">{`Provide, maintain and improve our Services to you`}</li>
      <li parentName="ul">{`Notify you about available updates and patches`}</li>
      <li parentName="ul">{`Process and validate your requests to the license server`}</li>
      <li parentName="ul">{`Monitor the usage of our Services`}</li>
      <li parentName="ul">{`Offer customer support and technical advice`}</li>
      <li parentName="ul">{`Detect, prevent, and address technical issues`}</li>
    </ul>
    <h3>{`Additional Forum Data Usage`}</h3>
    <p>{`Mondaic AG uses your account data to identify you on the forum and to create
pages specific to you, such as your profile page. The forum is access-restricted
to client and collaborators of Mondaic AG. Mondaic AG makes your account data
available to everyone who can access the forum, according to the forum
administrator’s configuration.`}</p>
    <p>{`When using the forum, Mondaic AG will additionally use your email address to`}</p>
    <ul>
      <li parentName="ul">{`notify you about posts and other activity on the forum`}</li>
      <li parentName="ul">{`reset your password and help keep your account secure`}</li>
      <li parentName="ul">{`contact you in special circumstances related to your account`}</li>
      <li parentName="ul">{`contact you about legal requests, like DMCA takedown requests`}</li>
    </ul>
    <p>{`You may provide additional data for your account, like a short biography, your
location, or your birthday, on the profile settings page for your account.
Mondaic AG makes that data available to others who can access the forum. You
don’t have to provide this additional information, and you can erase it at any
time.`}</p>
    <p>{`Mondaic AG stores your account data as long as your account remains open.`}</p>
    <h3>{`How are my data used when I get an unsolicited email from you?`}</h3>
    <p>{`If you get an unsolicited email from us, we have found your information (name,
email and/or function) on a public website. We regularly check for professionals
in relevant fields to reach out to them and market our software and services.
Collecting this data along with your stated interest allows us to either contact
you or help us to refrain from contacting you. In case we find that you are not
interested in our software and services, we'll hold on to that information and
email address such that we don't reach out again.  If you want all your personal
information deleted, please request so, and according to the European General
Data Protection Regulation we will immediately delete all your personal
information. Note that this might result in us contacting you again in the
future.`}</p>
    <h2>{`Service Providers`}</h2>
    <p>{`We may employ third party companies and individuals to facilitate our Service
(“Service Providers”), or assist us in analyzing how our Service is used.  These
third parties have access to your Personal Data only to perform these tasks on
our behalf and are obligated not to disclose or use it for any other purpose.`}</p>
    <p>{`We use Google Analytics for reporting website traffic, a web analytics service
offered by Google. For more information on the privacy practices of Google,
please visit the `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=en"
      }}>{`Google Privacy & Terms web
page`}</a>{`.`}</p>
    <p>{`We work with Stripe to process invoices payed by credit card. Their Privacy
Policy can be viewed `}<a parentName="p" {...{
        "href": "https://stripe.com/privacy"
      }}>{`here`}</a>{`.`}</p>
    <p>{`We use monday.com to process the contact form and manage our customer relations.
Using the "Contact Us" form on the website require the generation of cookies by
monday.com, which fall under essential cookies. To read monday.com's compliance
with the GDPR, see `}<a parentName="p" {...{
        "href": "http://www.monday.com/terms/gdpr"
      }}>{`this page`}</a>{`.`}</p>
    <p>{`We use Amazon Web Services to host our services and deliver digital goods, see
`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/privacy/"
      }}>{`this page`}</a>{` for their privacy policy.`}</p>
    <p>{`We use Heroku to host our services and as data storage. Please see `}<a parentName="p" {...{
        "href": "https://devcenter.heroku.com/articles/security-privacy-compliance"
      }}>{`this
page`}</a>{` and
links therein for details of how Heroku handles data privacy and compliance.`}</p>
    <p>{`We use Vultr to host our services and as data storage. Please see `}<a parentName="p" {...{
        "href": "https://www.vultr.com/legal/privacy/"
      }}>{`this
page`}</a>{` and links therein for details of how
Vultr handles data privacy and compliance.`}</p>
    <h2>{`Security`}</h2>
    <p>{`We value your trust in providing us your Personal Information, thus we are
striving to use commercially acceptable means of protecting it. But remember
that no method of transmission over the internet, or method of electronic
storage is totally secure and reliable, and we cannot guarantee its absolute
security.`}</p>
    <h2>{`Marketing`}</h2>
    <p>{`Mondaic AG would like to send you information about products and services of
ours that we think you might like.`}</p>
    <p>{`If you have agreed to receive marketing, you may always opt out at a later date.`}</p>
    <p>{`You have the right at any time to stop Mondaic AG from contacting you for
marketing purposes.`}</p>
    <p>{`If you no longer wish to be contacted for marketing purposes, please contact us
using the contact information further down this page.`}</p>
    <h2>{`What are your data protection rights?`}</h2>
    <p>{`Mondaic AG would like to make sure you are fully aware of all of your data
protection rights. Every user is entitled to the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The right to access`}</strong>{` – You have the right to request Mondaic AG for copies
of your personal data. We may charge you a small fee for this service.`}</li>
      <li parentName="ul"><strong parentName="li">{`The right to rectification`}</strong>{` – You have the right to request that Mondaic AG
correct any information you believe is inaccurate. You also have the right to
request Mondaic AG to complete the information you believe is incomplete.`}</li>
      <li parentName="ul"><strong parentName="li">{`The right to erasure`}</strong>{` – You have the right to request that Mondaic AG erase
your personal data, under certain conditions.`}</li>
      <li parentName="ul"><strong parentName="li">{`The right to restrict processing`}</strong>{` – You have the right to request that
Mondaic AG restrict the processing of your personal data, under certain
conditions.`}</li>
      <li parentName="ul"><strong parentName="li">{`The right to object to processing`}</strong>{` – You have the right to object to
Mondaic AG’s processing of your personal data, under certain conditions.`}</li>
      <li parentName="ul"><strong parentName="li">{`The right to data portability`}</strong>{` – You have the right to request that Mondaic AG
transfer the data that we have collected to another organization, or directly
to you, under certain conditions.`}</li>
    </ul>
    <p>{`If you make a request, we have one month to respond to you. If you would like to
exercise any of these rights, please contact us using the information further
down this page.`}</p>
    <h2>{`Cookies`}</h2>
    <p>{`Cookies are files with a small amount of data which may include an anonymous
unique identifier. Cookies are sent to your browser from a website and stored on
your device. We use cookies and similar tracking technologies to track the
activity on our website and to improve and analyze our service.`}</p>
    <p>{`For further information, visit `}<a parentName="p" {...{
        "href": "https://allaboutcookies.org"
      }}>{`https://allaboutcookies.org`}</a>{`.`}</p>
    <h3>{`How do we use cookies?`}</h3>
    <p>{`Mondaic AG uses cookies in a range of ways to improve your experience with our
Services, including:`}</p>
    <ul>
      <li parentName="ul">{`Keeping you signed in`}</li>
      <li parentName="ul">{`Understanding how you use our website`}</li>
    </ul>
    <h3>{`What types of cookies do we use?`}</h3>
    <p>{`There are a number of different types of cookies, however, our website uses:`}</p>
    <ul>
      <li parentName="ul">{`Functionality – Mondaic AG uses these cookies so that we recognize you on our
website and remember your previously selected preferences. These could include
what language you prefer and location you are in. A mix of first-party and
third-party cookies are used.`}</li>
    </ul>
    <h3>{`How to manage cookies`}</h3>
    <p>{`You can set your browser not to accept cookies, and the above website tells you
how to remove cookies from your browser. However, in a few cases, some of our
website features may not function as a result.`}</p>
    <h2>{`Links to other sites`}</h2>
    <p>{`Our Service may contain links to other sites. If you click on a third-party
link, you will be directed to that site. Note that these external sites are
not operated by us. Therefore, we strongly advise you to review the Privacy
Policy of these websites. We have no control over, and assume no
responsibility for the content, privacy policies, or practices of any
third-party sites or services.`}</p>
    <h2>{`Children’s privacy`}</h2>
    <p>{`Our Services do not address anyone under the
age of 18. We do not knowingly collect personal identifiable information from
children under 18. In the case we discover that a child under 18 has provided
us with personal information, we immediately delete this from our servers. If
you are a parent or guardian and you are aware that your child has provided
us with personal information, please contact us so that we will be able to do
necessary actions.`}</p>
    <h2>{`Changes to our Privacy Policy`}</h2>
    <p>{`This Privacy Policy may be updated from time to time and without prior notice
to you to reflect changes in our information practices. Changes to this
Privacy Policy are effective when they are posted on this page. We will
indicate at the bottom of this Privacy Policy when it was most recently
updated. You are encouraged to review this Privacy Policy periodically for
the latest information on our privacy practices.`}</p>
    <h2>{`How to contact us`}</h2>
    <p>{`If you have any questions about Mondaic AG’s privacy policy, the data we hold on
you, or you would like to exercise one of your data protection rights, please do
not hesitate to contact us.`}</p>
    <ul>
      <li parentName="ul">{`Send an email to `}<a parentName="li" {...{
          "href": "mailto:info@mondaic.com"
        }}>{`info@mondaic.com`}</a>{` or`}</li>
      <li parentName="ul">{`Get in touch with us using our `}<a parentName="li" {...{
          "href": "/impressum"
        }}>{`contact information`}</a>{`.`}</li>
    </ul>
    <h2>{`Last update to this Privacy Policy`}</h2>
    <p>{`November 5th, 2024`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      